import React, { createContext, useEffect, useState } from 'react';
import Client, { Checkout } from 'shopify-buy';
import localStorageService from '../utils/localStorageService';
import { isPlatformBrowser } from '../utils/platform';
import { getQueryParams } from '../utils/queryParams';

interface StoreContext {
  state: {
    client: Client;
    checkout: Checkout;
    adding: boolean;
    updating: boolean;
    loading: boolean;
    favourites: string[];
    successfulCheckout: Checkout;
    lastItemAdded?: any;
    sortedLineItems: any[];
  };
  addLineItem: (variantId: string, quantity?: number) => Promise<void>;
  updateLineItem: (lineItemId: string, quantity: number) => Promise<void>;
  removeLineItem: (lineItemId: string) => Promise<void>;
  addDiscount: (discountCode: string) => Promise<void>;
  removeDiscount: () => Promise<void>;
  toggleProductInFavourites: (productId: string) => void;
  setLastItemAdded: (product: any, variant: any) => void;
  resetLastItemAdded: () => void;
}

// Global state (React Context API)
const Context = createContext<StoreContext>({} as StoreContext);

const client = Client.buildClient({
  domain: process.env.GATSBY_SHOPIFY_STORE_URL,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN
} as Client.Config);

const ContextProvider = ({ children }) => {
  const initialFavouritesState = isPlatformBrowser ? (localStorageService.get<string[]>('pixwristFavourites') ?? []) : [];
  const initialState = {
    client,
    checkout: { id: null, lineItems: [] } as Checkout,
    adding: false,
    updating: false,
    loading: true,
    favourites: initialFavouritesState,
    successfulCheckout: null,
    sortedLineItems: []
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (isPlatformBrowser) {
      const initCheckout = async () => {
        // check for existing checkout (cart)
        const checkoutId = localStorage.getItem('checkoutId');
        let checkout: Checkout, successfulCheckout: Checkout, sortedLineItems: any[];

        try {
          if (checkoutId && checkoutId.includes('gid://')) {
            checkout = await state.client.checkout.fetch(checkoutId);
            sortedLineItems = JSON.parse(localStorage.getItem('sortedLineItems')) || [];
            console.log('storeContext checkout', checkout);
          } else {
            checkout = await state.client.checkout.create();
            sortedLineItems = [];
          }

          // WORKAROUND: the Cart API (that Shopify Buy SDK uses) does not maintain the state of a completed Cart that became an order
          if (window.location.pathname.includes('checkout-success')) {
            checkout = await state.client.checkout.create();
            sortedLineItems = [];
          }

          // remove deleted products from existing cart
          if (checkout.lineItems) {
            const deletedProductIds = checkout.lineItems
              .filter(lineItem => lineItem.variant === null)
              .map(lineItem => lineItem.id as string);

            if (deletedProductIds?.length) {
              checkout = await state.client.checkout.removeLineItems(checkout.id, deletedProductIds);
            }
          }

          localStorage.setItem('checkoutId', checkout.id as string);

          setState(prevState => {
            return { ...prevState, checkout, successfulCheckout, loading: false, sortedLineItems };
          });
        } catch (error) {
          localStorage.removeItem('checkoutId');
        }
      };

      initCheckout();
    }
  }, [state.client.checkout]);

  useEffect(() => {
    if (state.checkout?.id) {
      // check if discount code is in query param and apply it
      checkDiscountInQueryParam();
    }
  }, [state.checkout]);

  const addLineItem = async (variantId: string, quantity: number = 1) => {
    setState(prevState => {
      return { ...prevState, adding: true };
    });

    const checkout = await state.client.checkout.addLineItems(state.checkout.id, [{ variantId, quantity }]);
    const addedItem = checkout.lineItems.find(item => item.variant.id === variantId);

    const sortedLineItems = [...state.sortedLineItems, { id: addedItem.id, addedAt: Date.now() }];
    localStorage.setItem('sortedLineItems', JSON.stringify(sortedLineItems));

    setState(prevState => {
      return { ...prevState, checkout, adding: false, sortedLineItems };
    });
  };

  const removeLineItem = async (lineItemId: string) => {
    const checkout = await state.client.checkout.removeLineItems(state.checkout.id, [lineItemId]);

    const sortedLineItems = state.sortedLineItems.filter(item => item.id !== lineItemId);
    localStorage.setItem('sortedLineItems', JSON.stringify(sortedLineItems));

    setState(prevState => {
      return { ...prevState, checkout, sortedLineItems };
    });
  };

  const updateLineItem = async (lineItemId: string, quantity: number = 1) => {
    setState(prevState => {
      return { ...prevState, updating: true };
    });

    const checkout = await state.client.checkout.updateLineItems(state.checkout.id, [{ id: lineItemId, quantity }]);

    if (quantity === 0) {
      const sortedLineItems = state.sortedLineItems.filter(item => item.id !== lineItemId);
      localStorage.setItem('sortedLineItems', JSON.stringify(sortedLineItems));
    }

    setState(prevState => {
      return { ...prevState, checkout, updating: false };
    });
  };

  const addDiscount = async (discountCode: string) => {
    const checkout = await state.client.checkout.addDiscount(state.checkout.id, discountCode);
    setState(prevState => {
      return { ...prevState, checkout };
    });
  };

  const removeDiscount = async () => {
    const checkout = await state.client.checkout.removeDiscount(state.checkout.id);
    setState(prevState => {
      return { ...prevState, checkout };
    });
  };

  const toggleProductInFavourites = (productId: string) => {
    if (isPlatformBrowser) {
      const favourites = state.favourites.includes(productId)
        ? state.favourites.filter(id => id !== productId)
        : [...state.favourites, productId];

      localStorageService.set('pixwristFavourites', favourites);

      setState(prevState => {
        return { ...prevState, favourites };
      });
    }
  };

  const setLastItemAdded = (product: any, variant: any) => {
    setState(prevState => {
      return { ...prevState, adding: false, lastItemAdded: { product, variant } };
    });
  };

  const resetLastItemAdded = () => {
    setState(prevState => {
      return { ...prevState, adding: false, lastItemAdded: null };
    });
  };

  const checkDiscountInQueryParam = () => {
    const discountCode = getQueryParams().get('discount');

    if (discountCode && !state.checkout.discountApplications?.length) {
      addDiscount(discountCode);
    }
  };

  return (
    <Context.Provider
      value={{
        state,
        addLineItem,
        updateLineItem,
        removeLineItem,
        addDiscount,
        removeDiscount,
        toggleProductInFavourites,
        setLastItemAdded,
        resetLastItemAdded
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
